<template>
  <div style="font-size: 16px;">
    <b-row v-if="!d_componentFullPage">
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row v-if="!d_componentFullPage" style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['machine_learning_query'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row>
      <b-col v-if="!d_componentFullPage" sm="12" lg="2"></b-col>
      <b-col sm="12" :lg="d_componentFullPage ? 12 : 8">
        <b-card-header v-if="d_machineLearningDesign && d_machineLearningDesign.data && d_machineLearningDesign.data.general" header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
          <b-row>
            <b-col sm="12" lg="12">
              <h5>
                <i class="fa fa-info-circle"></i>
                {{ d_machineLearningDesign.data.general.name.val }}
              </h5>
            </b-col>
          </b-row>
        </b-card-header>
        <template v-if="d_machineLearningResult && d_machineLearningResult.length > 0">
          <template v-for="(result_item, result_item_index) in d_machineLearningResult">
            <template v-for="(ml, ml_index) in result_item.machine_learning">
              <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
                <b-row>
                  <b-col sm="12" lg="8">
                    <h6>
                      <i class="fa fa-calculator"></i>
                      {{ StoreLangTranslation.data['variables'][StoreLang] }}
                    </h6>
                  </b-col>
                  <b-col sm="12" lg="4" style="text-align: right;"></b-col>
                </b-row>
              </b-card-header>
              <b-card-body>
                <template v-for="(inp, inp_index) in ml.input_variable_details">
                  <b-row style="padding-top: 5px;">
                    <b-col sm="12" md="6">
                      <i class="fa fa-circle"></i>
                      {{ inp.label }}
                    </b-col>
                    <b-col sm="12" md="6">
                      <template v-if="inp.type">
                        <template v-if="inp.type === 'categorical'">
                          <b-form-select v-model="inp.selected">
                            <option v-for="(op, op_ind) in inp.options" :value="op.value">
                              {{ op.label }}
                            </option>
                          </b-form-select>
                        </template>
                        <template v-else-if="inp.type === 'numeric'">
                          <b-form-input type="number" v-model="inp.selected"></b-form-input>
                        </template>
                      </template>
                    </b-col>
                  </b-row>
                </template>
              </b-card-body>
              <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
                <b-row>
                  <b-col sm="12" lg="6">
                    <h6>
                      <i class="fa fa-calculator"></i>
                      {{ StoreLangTranslation.data['results'][StoreLang] }}
                    </h6>
                  </b-col>
                  <b-col sm="12" lg="2">
                  </b-col>
                  <b-col sm="12" lg="2">
                  </b-col>
                  <b-col sm="12" lg="2" style="text-align: right;">
                    <b-button variant="outline-primary" size="sm" @click="f_wanalyzerMachinelearningAnalyze(result_item_index, ml_index)">
                      <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-card-body>
                <b-row style="padding-top: 10px;">
                  <b-col cols="11">
                    <b-row>
                      <b-col cols="6">
                        <strong style="color: green;">
                          <i class="fa fa-hand-o-right"></i>
                          {{ ml.output_variable_details.label }}
                        </strong>
                      </b-col>
                      <b-col cols="3">
                        <strong v-if="ml.predict_result" style="color: green;"> {{ ml.predict_result.predict }} </strong>
                      </b-col>
                      <b-col cols="3"> </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
                <b-row>
                  <b-col sm="12" lg="6">
                    <h6>
                      <i class="fa fa-cogs"></i>
                      {{ StoreLangTranslation.data['model_features'][StoreLang] }}
                    </h6>
                  </b-col>
                  <b-col sm="12" lg="2"> </b-col>
                  <b-col sm="12" lg="2"> </b-col>
                  <b-col sm="12" lg="2" style="text-align: right;"></b-col>
                </b-row>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col sm="12" lg="6">
                    <i class="fa fa-dot-circle-o"></i>
                    {{ StoreLangTranslation.data['machine_learning_model'][StoreLang] }}
                  </b-col>
                  <b-col sm="12" lg="6">
                    {{ ml.type.replace('_', ' ') }}, <small>{{ ml.lib }}</small>
                  </b-col>
                </b-row>
                <b-row v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0">
                  <b-col sm="12" lg="6">
                    <i class="fa fa-dot-circle-o"></i>
                    {{ StoreLangTranslation.data['confusion_matrix'][StoreLang] }}
                  </b-col>
                  <b-col sm="12" lg="6">
                    <table style="width: 100%;">
                      <tr>
                        <th style="text-align: center;"></th>
                        <th colspan="1" style="text-align: center;"></th>
                        <th colspan="2" style="text-align: center;">{{ StoreLangTranslation.data['real_values'][StoreLang] }}</th>
                        <th style="text-align: center;"></th>
                      </tr>
                      <tr>
                        <th style="text-align: center;"></th>
                        <th style="text-align: center;"></th>
                        <th style="text-align: center; color: green;">{{ StoreLangTranslation.data['positive'][StoreLang] }}</th>
                        <th style="text-align: center; color: red;">{{ StoreLangTranslation.data['negative'][StoreLang] }}</th>
                        <th style="text-align: center;">Total</th>
                      </tr>
                      <tr>
                        <td rowspan="2" style="text-align: center;"><strong>{{ StoreLangTranslation.data['prediction_values'][StoreLang] }}</strong></td>
                        <td style="text-align: center; color: green;"><strong>{{ StoreLangTranslation.data['positive'][StoreLang] }}</strong></td>
                        <td style="text-align: center;" class="confusion-matrix" :title="StoreLangTranslation.data['true_positive_explanation'][StoreLang]">{{ ml.confusion_matrix[0][0] }}</td>
                        <td style="text-align: center;" class="confusion-matrix" :title="StoreLangTranslation.data['false_positive_explanation'][StoreLang]">{{ ml.confusion_matrix[0][1] }}</td>
                        <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][0]) + parseInt(ml.confusion_matrix[0][1]) }}</td>
                      </tr>
                      <tr>
                        <td style="text-align: center; color: red;"><strong>Negatif</strong></td>
                        <td style="text-align: center;" class="confusion-matrix" :title="StoreLangTranslation.data['false_negative_explanation'][StoreLang]">{{ ml.confusion_matrix[1][0] }}</td>
                        <td style="text-align: center;" class="confusion-matrix" :title="StoreLangTranslation.data['true_negative_explanation'][StoreLang]">{{ ml.confusion_matrix[1][1] }}</td>
                        <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[1][0]) + parseInt(ml.confusion_matrix[1][1]) }}</td>
                      </tr>
                      <tr>
                        <td style="text-align: center;"></td>
                        <td style="text-align: center;">Total</td>
                        <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][0]) + parseInt(ml.confusion_matrix[1][0]) }}</td>
                        <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][1]) + parseInt(ml.confusion_matrix[1][1]) }}</td>
                        <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][0]) + parseInt(ml.confusion_matrix[0][1]) + parseInt(ml.confusion_matrix[1][0]) + parseInt(ml.confusion_matrix[1][1]) }}</td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
                <template v-if="ml.cluster_centers_ && ml.cluster_centers_.length > 0" style="padding-top: 10px;">
                  <b-row>
                    <b-col sm="12" lg="6">
                      <i class="fa fa-dot-circle-o"></i>
                      {{ StoreLangTranslation.data['cluster_centers'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="6">
                      <table style="width: 100%;">
                        <tr>
                          <th></th>
                          <th v-for="(input_var, input_var_ind) in ml.input_variables" class="confusion-matrix">
                            <span :title="input_var">
                                {{ input_var.length > 10 ? input_var.substring(0, 10) + '...' : input_var }}
                              </span>
                          </th>
                        </tr>
                        <tr v-for="(rw_cluster, rw_cluster_ind) in ml.cluster_centers_">
                          <td>{{ rw_cluster_ind + 1 }} . küme</td>
                          <td v-for="(col_cluster, col_cluster_ind) in rw_cluster">
                            <span :title="col_cluster">{{ parseFloat(col_cluster).toFixed(2) }}</span>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </template>
                <template v-if="ml.test_size !== undefined">
                  <b-row>
                    <b-col cols="6">
                      <i class="fa fa-dot-circle-o"></i>
                      {{ StoreLangTranslation.data['test_size'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">{{ ml.test_size }}</b-col>
                  </b-row>
                </template>
                <template v-if="ml.random_state !== undefined">
                  <b-row>
                    <b-col cols="6">
                      <i class="fa fa-dot-circle-o"></i>
                      {{ StoreLangTranslation.data['random_state'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">{{ ml.random_state }}</b-col>
                  </b-row>
                </template>
                <template v-if="ml.kernel_type !== undefined">
                  <b-row>
                    <b-col cols="6">
                      <i class="fa fa-dot-circle-o"></i>
                      {{ StoreLangTranslation.data['kernel_type'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">{{ ml.kernel_type }}</b-col>
                  </b-row>
                </template>
                <template v-if="ml.init !== undefined">
                  <b-row>
                    <b-col cols="6">
                      <i class="fa fa-dot-circle-o"></i>
                      {{ StoreLangTranslation.data['init'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">{{ ml.init }}</b-col>
                  </b-row>
                </template>
                <template v-if="ml.n_clusters !== undefined">
                  <b-row>
                    <b-col cols="6">
                      <i class="fa fa-dot-circle-o"></i>
                      {{ StoreLangTranslation.data['n_clusters'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">{{ ml.n_clusters }}</b-col>
                  </b-row>
                </template>
                <template v-if="ml.accuracy !== undefined">
                  <b-row>
                    <b-col cols="6">
                      <i class="fa fa-dot-circle-o"></i>
                      {{ StoreLangTranslation.data['accuracy'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">% {{ (ml.accuracy * 100).toFixed(0) }}</b-col>
                  </b-row>
                </template>
                <template v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0" style="padding-top: 10px;">
                  <b-row>
                    <b-col cols="6">
                      <i class="fa fa-dot-circle-o"></i>
                      {{ StoreLangTranslation.data['precision'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">{{ (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[0][1]))).toFixed(2) }}</b-col>
                  </b-row>
                </template>
                <template v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0" style="padding-top: 10px;">
                  <b-row>
                    <b-col cols="6">
                      <i class="fa fa-dot-circle-o"></i>
                      {{ StoreLangTranslation.data['recall'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">
                      {{ (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[1][0]))).toFixed(2) }}
                    </b-col>
                  </b-row>
                </template>
                <template v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0" style="padding-top: 10px;">
                  <b-row>
                    <b-col cols="6">
                      <i class="fa fa-dot-circle-o"></i>
                      F1 score
                    </b-col>
                    <b-col cols="6">
                      {{ ( 2 * ( (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[0][1]))) * (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[1][0]))) ) / ( (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[0][1]))) + (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[1][0]))) ) ).toFixed(2) }}
                    </b-col>
                  </b-row>
                </template>
                <template v-if="ml.roc && ml.roc.auc" style="padding-top: 10px;">
                  <b-row>
                    <b-col cols="6">
                      <i class="fa fa-dot-circle-o"></i>
                      ROC (auc)
                    </b-col>
                    <b-col cols="6">{{ (ml.roc.auc).toFixed(2) }}</b-col>
                  </b-row>
                </template>
                <template v-for="(fig, fig_ind) in ml.figure_list">
                  <b-row style="padding-top: 10px;">
                    <b-col cols="12">
                      <img :src="fig.figure" style="width: 50%;">
                    </b-col>
                  </b-row>
                </template>
              </b-card-body>
            </template>
          </template>
        </template>
      </b-col>
      <b-col v-if="!d_componentFullPage" sm="12" lg="2"></b-col>
    </b-row>

  </div>
</template>

<script>
import WanalyzerService from '@/services/wanalyzer';
import { mapGetters } from 'vuex';
export default {
  name: 'MachineLearningQuery',
  components: {},
  props: {
    p_projectId: {
      type: String,
      required: false
    },
    p_machineLearningId: {
      type: String,
      required: false
    }
  },
  data: () => {
    return {
      d_componentFullPage: false,
      d_machineLearningResult: [],
      d_projectId: '',
      d_machineLearningDesign: '',
      d_machineLearningId: '',
      d_selectedMachineLearningdata: { 'show': false, 'data': {} },
    }
  },
  computed: {
    ...mapGetters({
      StoreLangTranslation: 'StoreLangTranslation',
      StoreDevice: 'StoreDevice',
      StoreUser: 'StoreUser',
      StoreLoading: 'StoreLoading',
      StoreModal: 'StoreModal',
      StoreLang: 'StoreLang',
    })
  },
  created () {
    if (this.p_projectId) {
      this.d_componentFullPage = true;
      this.d_projectId = this.p_projectId;
    } else if (!this.p_projectId) {
      if (this.$route.query.project_id !== undefined) {
        this.d_projectId = this.$route.query.project_id;
      }
    }
    if (this.p_machineLearningId) {
      this.d_machineLearningId = this.p_machineLearningId;
    } else if (!this.p_machineLearningId) {
      if (this.$route.query.machine_learning_id !== undefined) {
        this.d_machineLearningId = this.$route.query.machine_learning_id;
      }
    }
    if (this.d_projectId && this.d_machineLearningId) {
      this.f_wanalyzerMachinelearningDataGet();
    }
  },
  methods: {
    f_wanalyzerMachinelearningDataGet: function () {
      // console.log('this.d_projectId         : ', this.d_projectId);
      // console.log('this.d_machineLearningId : ', this.d_machineLearningId);
      if (this.d_projectId && this.d_machineLearningId) {
        let query = 'project_id=' + this.d_projectId;
        query += '&machine_learning_id=' + this.d_machineLearningId;
        let data = {};
        WanalyzerService.wanalyzer_machinelearning_data_get(query, data)
          .then(resp => {
              if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                this.d_machineLearningResult = resp.data.machine_learning.analyze_results;
                this.d_machineLearningDesign = resp.data.machine_learning.design;
              } else {
                let txt = 'status_code = ' + resp.data.status_code + '\n';
                txt += 'status_message = ' + resp.data.status_message;
                alert(txt);
              }
          });
      }
    },
    f_wanalyzerMachinelearningAnalyze: function (analyze_index, ml_index) {
      // console.log('this.d_machineLearningResult[analyze_index].machine_learning[ml_index].input_variable_details : ', this.d_machineLearningResult[analyze_index].machine_learning[ml_index].input_variable_details);
      this.d_machineLearningResult[analyze_index].machine_learning[ml_index].predict_result = '';
      this.$forceUpdate();
      let query = 'project_id=' + this.d_projectId;
      query += '&machine_learning_id=' + this.d_machineLearningId;
      query += '&analyze_result_index=' + this.d_machineLearningResult[analyze_index].ar_index.toString();
      query += '&machine_learning_index=' + this.d_machineLearningResult[analyze_index].machine_learning[ml_index].ml_index.toString();
      let data = {
        'input_list': []
      };
      let is_all_input_completed = true;
      for (let i in this.d_machineLearningResult[analyze_index].machine_learning[ml_index].input_variable_details) {
        let input_data = this.d_machineLearningResult[analyze_index].machine_learning[ml_index].input_variable_details[i];
        if (input_data.selected === undefined || input_data.selected === '') {
          is_all_input_completed = false;
          break;
        }
        let new_inp = {
          'value': input_data.selected,
          'label': input_data.label,
          'column_index': input_data.column_index
        }
        data.input_list.push(new_inp);
      }
      if (is_all_input_completed) {
        WanalyzerService.wanalyzer_machinelearning_predict(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              let predict_result = resp.data.predict;
              // console.log(typeof predict_result.predict);
              if (typeof predict_result.predict === 'number') {
                predict_result.predict = predict_result.predict.toFixed(2);
              }
              this.d_machineLearningResult[analyze_index].machine_learning[ml_index].predict_result = predict_result;
              this.$forceUpdate();
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
            }
          });
      } else {
        alert('Please completed inputs');
      }
    },
  }
}

</script>

