import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  login (query, data) {
    let route = '';
    if (query) {
      route = 'v1/login?' + query;
    } else {
      route = 'v1/login';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  wanalyzer_machinelearning_data_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/wanalyzer/machinelearning/data/get?' + query;
    } else {
      route = 'v1/wanalyzer/machinelearning/data/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  wanalyzer_machinelearning_predict (query, data) {
    let route = '';
    if (query) {
      route = 'v1/wanalyzer/machinelearning/predict?' + query;
    } else {
      route = 'v1/wanalyzer/machinelearning/predict';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  wanalyzer_project_details_columns_wai_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/wanalyzer/project/details/columns/wai/get?' + query;
    } else {
      route = 'v1/wanalyzer/project/details/columns/wai/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  wanalyzer_project_cell_calculate (query, data) {
    let route = '';
    if (query) {
      route = 'v1/wanalyzer/project/cell/calculate?' + query;
    } else {
      route = 'v1/wanalyzer/project/cell/calculate';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  wanalyzer_project_stats_result_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/wanalyzer/project/stats/result/list?' + query;
    } else {
      route = 'v1/wanalyzer/project/stats/result/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  wanalyzer_project_statistics_analyze_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/wanalyzer/project/statistics/analyze/list?' + query;
    } else {
      route = 'v1/wanalyzer/project/statistics/analyze/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  wanalyzer_project_dataset_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/wanalyzer/project/dataset/get?' + query;
    } else {
      route = 'v1/wanalyzer/project/dataset/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  }
};
